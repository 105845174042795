import _ from "lodash";
import moment from "moment";

/**
 * Get part of day
 * @param {object} sun Sun entity
 * @returns
 */
export const partOfDay = (sun) => {
  if (!sun) return null;

  let sunStates = {};
  const now = moment();
  const dayOfYear = now.dayOfYear();

  _.map(sun.attributes, (value, key) => {
    if (key.startsWith("next_")) {
      sunStates[key.substr(5)] = moment(value).dayOfYear(dayOfYear);
    }
  });

  if (now.isBefore(sunStates.rising) || now.isAfter(sunStates.dusk))
    return "night";
  else if (
    now.isBetween(sunStates.rising, moment(sunStates.noon).subtract(1, "hour"))
  )
    return "morning";
  else if (
    now.isBetween(
      moment(sunStates.noon).subtract(1, "hour"),
      moment(sunStates.noon).add(1, "hour")
    )
  )
    return "noon";
  else if (now.isBetween(sunStates.noon, sunStates.setting)) return "afternoon";
  else if (now.isBetween(sunStates.setting, sunStates.dusk)) return "evening";
};
