export const createActivity = (activity = null) => {
  return {
    type: "CREATE_ACTIVITY",
    payload: activity,
  };
};

export const closeActivity = () => {
  return (dispatch) => {
    dispatch({ type: "CLOSE_ACTIVITY" });

    setTimeout(() => {
      dispatch({ type: "DELETE_ACTIVITY" });
    }, 300);
  };
};
