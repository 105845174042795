import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { closeActivity } from "../../actions/ManageActivities";
import TopNavbar from "../TopNavbar";
import Icon from "../Icon";
import "../../styles/Persons.scss";

class Persons extends React.PureComponent {
  getPersonsEntities() {
    let persons = {};

    _.map(this.props.entities, (entity, key) => {
      if (key.includes("person.")) {
        persons[key.split(".")[1]] = entity;
      }
    });

    return persons;
  }

  getPersonsList() {
    var persons = this.getPersonsEntities();
    if (_.size(persons)) {
      return _.map(persons, (person, key) => {
        return (
          <div className="card" key={key}>
            <div className="title">{person.attributes.friendly_name}</div>
          </div>
        );
      });
    } else {
      return <div className="no-message"></div>;
    }
  }

  render() {
    return (
      <div className="persons activity-layout">
        <div>
          <TopNavbar
            title="کاربران"
            rightIcon={
              <Icon
                icon="mdiArrowRight"
                onClick={() => this.props.closeActivity()}
              />
            }
          />
        </div>
        <div className="cards-list">{this.getPersonsList()}</div>
      </div>
    );
  }
}

const mapStateToProps = ({ HASSEntities }) => {
  return { entities: HASSEntities };
};

export default connect(mapStateToProps, { closeActivity })(Persons);
