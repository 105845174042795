const MainMenu = (MainMenu = false, action) => {
  switch (action.type) {
    case "OPEN_MAIN_MENU":
      return true;
    case "CLOSE_MAIN_MENU":
      return false;
    default:
      return MainMenu;
  }
}

export default MainMenu;
