import React from "react";
import { Flex } from "antd-mobile";
import Icon from "../Icon";

class Switch extends React.Component {
  changeState = () => {
    window.HASS.callService("switch", "toggle", {
      entity_id: this.props.entity_id,
    });
  };

  render() {
    return (
      <Flex
        direction="column"
        className="entity switch"
        data-state={this.props.state}
        onClick={this.changeState}
        data-entity-id={this.props.entity_id}
      >
        <Flex.Item className="state">
          <span>
            {!Number.isNaN(parseInt(this.props.additional.state))
              ? this.props.additional.state + " وات"
              : ""}
          </span>
        </Flex.Item>
        <Flex.Item className="content">
          <Icon icon="mdiPowerSocketEu" />
        </Flex.Item>
        <Flex.Item className="title">
          <span>{this.props.attributes.friendly_name}</span>
        </Flex.Item>
      </Flex>
    );
  }
}

export default Switch;
