import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { closeActivity } from "../../actions/ManageActivities";
import TopNavbar from "../TopNavbar";
import Icon from "../Icon";
import "../../styles/Notifications.scss";

class Notifications extends React.PureComponent {
  dismissNotification(id) {
    window.HASS.callService("persistent_notification", "dismiss", {
      notification_id: id,
    });
  }

  getNotificationsEntities() {
    let notifications = [];

    _.map(this.props.entities, (entity, key) => {
      if (key.includes("persistent_notification.")) {
        notifications.push(entity);
      }
    });

    return notifications;
  }

  getNotificationsList() {
    var notifications = this.getNotificationsEntities();

    if (_.size(notifications)) {
      // Sort by last update in descending order.
      notifications = _.sortBy(notifications, ["last_updated"]);
      notifications = _.reverse(notifications);

      return _.map(notifications, (notification) => {
        const notice = this.parseNotification(notification);

        // Skip for unsupported notifications.
        if (!notice) return;

        return (
          <div className={`card ${notice.style}`} key={notification.entity_id}>
            <div className="title">{notice.title}</div>
            <div className="text">{notice.message}</div>
            <div className="action">
              <div
                role="button"
                onClick={() => this.dismissNotification(notification.entity_id)}
              >
                حذف
              </div>
            </div>
          </div>
        );
      });
    } else {
      return <div className="no-message">هیچ اعلانی وجود ندارد.</div>;
    }
  }

  parseNotification(notice) {
    const noticeKey = notice.entity_id.split(".")[1];
    switch (noticeKey) {
      case "http_login":
        var ip = notice.attributes.message.match(/\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/);
        return {
          style: "warning",
          title: "تلاش ناموفق برای ورود",
          message:
            `یک تلاش ناموفق از آدرس ${ip} برای ورود به سیستم ثبت شده است، نام کاربری یا رمزعبور نادرست بوده است.`,
        };

      case "config_entry_discovery":
        return {
          style: "info",
          title: "دستگاه جدیدی شناسایی شد",
          message:
            "دستگاه جدیدی در شبکه‌ی شما شناسایی شده است که می‌توانید آن را برای کنترل کردن تنظیم کنید.",
        };

      case "supervisor_issue_free_space":
        // TODO: Add available space to message.
        return {
          style: "danger",
          title: "کم بودن فضای آزاد دیسک",
          message:
            "فضای آزاد دیسک کمتر از ۱ گیگابایت است که ممکن است منجر به بروز مشکل در کارایی سیستم شود.",
        };

      default:
        console.warn(`Unsupported Notification: ${noticeKey}`, notice);
        return false;
    }
  }

  render() {
    return (
      <div className="notifications activity-layout">
        <div>
          <TopNavbar
            title="اعلان‌ها"
            rightIcon={
              <Icon
                icon="mdiArrowRight"
                onClick={() => this.props.closeActivity()}
              />
            }
          />
        </div>
        <div className="cards-list">{this.getNotificationsList()}</div>
      </div>
    );
  }
}

const mapStateToProps = ({ HASSEntities }) => {
  return { entities: HASSEntities };
};

export default connect(mapStateToProps, { closeActivity })(Notifications);
