import React from "react";
import { connect } from "react-redux";
import { CloseMainMenu } from "../actions/MainMenu";
import { createActivity } from "../actions/ManageActivities";
import { Flex } from "antd-mobile";
import TopNavbar from "./TopNavbar";
import Icon from "./Icon";
import Settings from "./Activities/Settings";
import Persons from "./Activities/Persons";
import "../styles/MainMenu.scss";

class MainMenu extends React.Component {
  open(activityName) {
    switch (activityName) {
      case "settings":
        this.props.createActivity({
          name: "settings",
          component: <Settings />,
        });
        break;

      case "persons":
        this.props.createActivity({
          name: "persons",
          component: <Persons />,
        });
        break;

      default:
        break;
    }

    this.props.CloseMainMenu();
  }

  render() {
    return (
      <div className="main-menu">
        <Flex
          className="flexbox-items-grow-0"
          direction="column"
          justify="between"
          align="stretch"
        >
          <Flex.Item className="menu-header">
            <TopNavbar
              rightIcon={
                <Icon icon="mdiClose" onClick={this.props.CloseMainMenu} />
              }
            />
          </Flex.Item>
          <Flex.Item className="menu-content">
            <ul>
              <li className="users" onClick={() => this.open("persons")}>
                <Icon icon="mdiAccountGroup" />
                <span>کاربران</span>
              </li>
              <li className="devices">
                <Icon icon="mdiDevices" />
                <span>دستگاه‌ها</span>
              </li>
              <li className="settings" onClick={() => this.open("settings")}>
                <Icon icon="mdiCog" />
                <span>تنظیمات</span>
              </li>
            </ul>
          </Flex.Item>
          <Flex.Item className="menu-footer">
            <TopNavbar
              rightIcon={[]}
              leftIcons={
                <span
                  className="logout-button"
                  onClick={() => window.HASS.logout()}
                >
                  <Icon icon="mdiLogout" />
                  <strong>خروج از حساب</strong>
                </span>
              }
            />
          </Flex.Item>
        </Flex>
      </div>
    );
  }
}

export default connect(null, {
  CloseMainMenu,
  createActivity,
})(MainMenu);
