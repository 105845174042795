import { Toast } from "antd-mobile";

/**
 * Show Toast with fade animation
 * @param {Any} toast Function to create toast
 * @param {number} duration duration of showing toast in second
 */
export function showToast(toast, duration = null) {
  toast();
  var interval = setInterval(() => {
    if (document.querySelector(".am-toast")) {
      clearInterval(interval);
      document.querySelector(".am-toast").classList.add("visible");
      if (duration) {
        setTimeout(hideToast, duration * 1000);
      }
    }
  }, 100);
}

/**
 * Hide Toast with fade animation
 * @param {?Function} callback
 */
export function hideToast(callback = null) {
  if (document.querySelector(".am-toast")) {
    document.querySelector(".am-toast").classList.remove("visible");
  }
  setTimeout(() => {
    Toast.hide();
    if (callback) {
      callback();
    }
  }, 500);
}
