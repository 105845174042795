import React from "react";
import { connect } from "react-redux";
import { closeActivity } from "../actions/ManageActivities";
import TopNavbar from "./TopNavbar";
import Icon from "./Icon";
import Entity from "./Entity";
import "../styles/RoomInfo.scss";

class RoomInfo extends React.Component {
  renderEntities() {
    let entities = [];

    this.props.entities.forEach((entity) => {
      const key = typeof entity === "object" ? entity.main : entity;

      if (!this.props.HASSEntities[key]) return;

      entities.push(
        <Entity
          key={key}
          {...this.props.HASSEntities[key]} // TODO: Pass HA Entity in one prop
          additional={
            entity.additional
              ? this.props.HASSEntities[entity.additional]
              : false
          }
        />
      );
    });

    if (entities.length)
      return (
        <div className="entities-list">
          <div className="row">{entities}</div>
        </div>
      );
    else return this.renderEmptyList();
  }

  renderEmptyList() {
    return (
      <div>
        <div className="empty-list">
          <div className="icon">
            <Icon icon={this.props.icon} />
          </div>
          <div className="message">
            هیچ دستگاهی برای <strong>{this.props.name}</strong> ثبت نشده
          </div>
        </div>
      </div>
    );
  }

  render() {
    const entities = this.props.entities.length
      ? this.renderEntities()
      : this.renderEmptyList();

    return (
      <div className="activity-layout">
        <div>
          <TopNavbar
            title={this.props.name}
            rightIcon={
              <Icon icon="mdiArrowRight" onClick={this.props.closeActivity} />
            }
          />
        </div>
        {entities}
      </div>
    );
  }
}

const mapStateToProps = ({ HASSEntities }) => {
  return { HASSEntities };
};

export default connect(mapStateToProps, { closeActivity })(RoomInfo);
