import React from "react";
import { Flex } from "antd-mobile";
import Icon from "../Icon";

class Temperature extends React.Component {
  render() {
    return (
      <Flex
        direction="column"
        data-entity-id={this.props.entity_id}
        className="entity sensor temperature no-ripple"
        data-state={!Number.isNaN(parseInt(this.props.state)) ? "on" : "unavailable"}
      >
        <Flex.Item className="state">
          <span>
            {!Number.isNaN(parseInt(this.props.state)) ? this.props.state : ""}
            <span className="c">°C</span>
          </span>
        </Flex.Item>
        <Flex.Item className="content">
          <Icon icon="mdiThermometer" />
        </Flex.Item>
        <Flex.Item className="title">
          <span>{this.props.attributes.friendly_name}</span>
        </Flex.Item>
      </Flex>
    );
  }
}

export default Temperature;
