import React from "react";
import { Flex, Modal } from "antd-mobile";
import Icon from "../Icon";
import Hammer from "hammerjs";

class Light extends React.Component {
  constructor(props) {
    super(props);

    this.brightness = props.attributes.brightness
      ? Math.ceil(props.attributes.brightness / 2.55)
      : 0;
    this.state = {
      brightness: this.brightness,
    };

    this.$entity = React.createRef();
    this.$state = React.createRef();
    this.$live = React.createRef();
  }

  componentDidMount() {
    this.$state.current.textContent = `٪${this.brightness}`;

    var hammer = new Hammer.Manager(this.$entity.current, {
      inputClass: Hammer.TouchInput,
    });
    this.HammerPan = new Hammer.Pan({
      event: "pan",
      enable: false,
      direction: Hammer.DIRECTION_VERTICAL,
    });

    hammer.add(new Hammer.Tap());
    hammer.add(this.HammerPan);
    hammer.add(
      new Hammer.Press({
        time: 500,
      })
    );

    hammer.on("tap", this.onTap);
    hammer.on("press", this.onPress);
    hammer.on("pan", this.onPan);
    hammer.on("panend pancancel pressup", this.onPanEnd);
  }

  componentDidUpdate() {
    const brightness = this.props.attributes.brightness
      ? Math.ceil(this.props.attributes.brightness / 2.55)
      : 0;

    if (brightness !== this.brightness) {
      this.brightness = brightness;
      this.$state.current.textContent = this.brightness
        ? `٪${this.brightness}`
        : "";
    }
  }

  calcBrightness(value) {
    value =
      this.cachedBrightness +
      -value / (this.$entity.current.clientHeight / 100);
    return value > 100 ? 100 : value < 1 ? 1 : parseInt(value);
  }

  onTap = () => {
    this.showBrightnessTip();
    window.HASS.callService("light", "toggle", {
      entity_id: this.props.entity_id,
    });
  };

  onPress = () => {
    if (this.props.state !== "on") return;
    this.HammerPan.set({ enable: true });

    this.cachedBrightness = this.brightness;

    this.$live.current.textContent = `٪${this.brightness}`;
    this.$entity.current.classList.add("on-pan");
    this.$entity.current.style.backgroundSize = `100% ${this.brightness}%`;

    this.setBrightnessInterval = setInterval(this.setBrightness, 500);
  };

  onPan = (e) => {
    e.preventDefault();
    const brightness = this.calcBrightness(e.deltaY);
    this.brightness = brightness;

    this.$live.current.textContent = `٪${brightness}`;
    this.$entity.current.style.backgroundSize = `100% ${brightness}%`;
  };

  onPanEnd = (e) => {
    if (!this.HammerPan.options.enable) return;
    this.HammerPan.set({ enable: false });

    const brightness = this.calcBrightness(e.deltaY);
    this.brightness = brightness;
    this.$entity.current.classList.remove("on-pan");
    this.$state.current.textContent = `٪${this.brightness}`;

    clearInterval(this.setBrightnessInterval);
    this.setBrightness(true);
  };

  setBrightness = (force = false) => {
    if (!force) {
      if (
        this.brightness === this.state.brightness ||
        this.brightness === this.lastSentBrightness
      )
        return;
    }
    this.lastSentBrightness = this.brightness;

    window.HASS.callService("light", "turn_on", {
      entity_id: this.props.entity_id,
      brightness: parseInt(this.brightness * 2.55),
    });
  };

  // TODO: Reset Tips-Light-Brightness option in setting
  showBrightnessTip = () => {
    if (window.localStorage.getItem("Tips-Light-Brightness")) return;
    Modal.alert(
      "راهنما",
      "برای تغییر میزان روشنایی لامپ، نماد لامپ را لمس کرده و کمی نگه دارید، سپس میزان روشنایی آن را تنظیم کنید.",
      [
        {
          text: "متوجه شدم",
          onPress: () =>
            window.localStorage.setItem("Tips-Light-Brightness", true),
        },
      ]
    );
  };

  render() {
    return (
      <div
        ref={this.$entity}
        data-state={this.props.state}
        data-entity-id={this.props.entity_id}
        className="entity light no-ripple am-flexbox am-flexbox-dir-column am-flexbox-align-center"
      >
        <Flex.Item className="state">
          <span ref={this.$state}></span>
        </Flex.Item>
        <Flex.Item className="content">
          <Icon
            icon={
              this.props.state === "on" ? "mdiLightbulb" : "mdiLightbulbOutline"
            }
          />
        </Flex.Item>
        <Flex.Item className="title">
          <span>{this.props.attributes.friendly_name}</span>
        </Flex.Item>
        <Flex.Item className="brightness">
          <span ref={this.$live} className="live"></span>
        </Flex.Item>
      </div>
    );
  }
}

export default Light;
