import React from "react";
import { Flex } from "antd-mobile";
import Icon from "../Icon";

class Brightness extends React.Component {
  render() {
    return (
      <Flex
        direction="column"
        className="entity sensor brightness no-ripple"
        data-state={!Number.isNaN(parseInt(this.props.state)) ? "on" : "unavailable"}
        data-entity-id={this.props.entity_id}
      >
        <Flex.Item className="state">
          <span>
            {!Number.isNaN(parseInt(this.props.state)) ? `٪${this.props.state}` : ""}
          </span>
        </Flex.Item>
        <Flex.Item className="content">
          <Icon icon="mdiBrightness4" />
        </Flex.Item>
        <Flex.Item className="title">
          <span>{this.props.attributes.friendly_name}</span>
        </Flex.Item>
      </Flex>
    );
  }
}

export default Brightness;
