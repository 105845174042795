import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { CloseMainMenu } from "../actions/MainMenu";
import ActivityManager from "./ActivityManager";
import MainMenu from "./MainMenu";
import "../styles/Main.scss";

const notificationCount = _.random(2);

class Main extends React.Component {
  render() {
    return (
      <div
        className={`main-page bootstrap-wrapper ${
          this.props.mainMenu ? "menu-open" : ""
        }`.trim()}
      >
        <MainMenu />
        <div className="main-content">
          <div
            className="main-content-overlay"
            onClick={this.props.CloseMainMenu}
          ></div>
          <ActivityManager notificationCount={notificationCount} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ mainMenu }) => {
  return { mainMenu };
};

export default connect(mapStateToProps, { CloseMainMenu })(Main);
