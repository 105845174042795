import React from "react";
import _ from "lodash";
import { Icon as MDIcon } from "@mdi/react";

class Icon extends React.Component {
  static defaultProps = {
    className: "",
  };

  render() {
    var MDIcons = require("@mdi/js");
    var icon = MDIcons[this.props.icon];

    if (!icon) {
      console.error(
        `Could not find ${this.props.icon} in Material Design Icons`
      );
      icon = MDIcons.mdiCancel;
    }

    const { className } = this.props;
    const iconName = this.props.icon;
    const props = _.omit(this.props, ["icon", "className"]);

    return (
      <MDIcon
        path={icon}
        className={`mdi ${iconName} ${className}`.trim()}
        {...props}
      />
    );
  }
}

export default Icon;
