import React from "react";
import _ from "lodash";
import { partOfDay } from "../library/time";
import "../styles/WelcomeText.scss";

class WelcomeText extends React.Component {
  static defaultProps = {
    sun: null,
    onClick: () => {},
    notificationCount: 0,
  };

  getTimeText() {
    switch (partOfDay(this.props.sun)) {
      case "morning":
        return "صبح بخیر";

      case "noon":
        return "ظهر بخیر";

      case "afternoon":
        return "بعد از ظهر بخیر";

      case "evening":
        return "عصر بخیر";

      case "night":
        return "شب بخیر";

      default:
        return "وقت بخیر";
    }
  }

  getTextSummary() {
    if (this.props.notificationCount) {
      return "اعلان جدید داری";
    } else {
      return ["همه چی روبه راهه", "خبر خاصی نیست"][_.random(1)];
    }
  }

  render() {
    const notificationCount = this.props.notificationCount;

    return (
      <div
        className={`welcome-text ${notificationCount ? "active" : ""}`}
        onClick={() =>
          this.props.notificationCount ? this.props.onClick() : null
        }
      >
        <span>{this.getTimeText()}</span>، <span>{this.getTextSummary()}</span>
      </div>
    );
  }
}

export default WelcomeText;
