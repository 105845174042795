import React from "react";
import { connect } from "react-redux";
import Icon from "./Icon";
import { Flex } from "antd-mobile";
import "../styles/WeatherSummary.scss";

// TODO: Fix weather status in night.

const weatherStatuses = {
  "clear-night": {
    icon: "mdiWeatherNight",
    label: "آسمان صاف",
  },
  cloudy: {
    icon: "mdiWeatherCloudy",
    label: "ابری",
  },
  exceptional: {
    icon: "mdiAlertCircleOutline",
    label: "وضعیت نادر",
  },
  fog: {
    icon: "mdiWeatherFog",
    label: "مه",
  },
  hail: {
    icon: "mdiWeatherHail",
    label: "تگرگ",
  },
  lightning: {
    icon: "mdiWeatherLightning",
    label: "رعد و برق",
  },
  "lightning-rainy": {
    icon: "mdiWeatherLightningRainy",
    label: "بارش و رعد و برق",
  },
  partlycloudy: {
    icon: "mdiWeatherPartlyCloudy",
    label: "نیمه ابری",
  },
  pouring: {
    icon: "mdiWeatherPouring",
    label: "بارش شدید",
  },
  rainy: {
    icon: "mdiWeatherRainy",
    label: "بارانی",
  },
  snowy: {
    icon: "mdiWeatherSnowy",
    label: "برفی",
  },
  "snowy-rainy": {
    icon: "mdiWeatherSnowyRainy",
    label: "برفی و بارانی",
  },
  sunny: {
    icon: "mdiWeatherSunny",
    label: "آفتابی",
  },
  windy: {
    icon: "mdiWeatherWindy",
    label: "باد",
  },
  "windy-variant": {
    icon: "mdiWeatherWindyVariant",
    label: "باد",
  },
};

class WeatherSummary extends React.Component {
  dayOrNight() {
    if (!this.props.sun) return "day";

    switch (this.props.sun.state) {
      case "above_horizon":
        return "day";

      case "below_horizon":
        return "night";

      default:
        return "day";
    }
  }

  getWeatherAttributes() {
    if (typeof this.props.weather.attributes !== "undefined") {
      return this.props.weather.attributes;
    }

    return "-";
  }

  render() {
    const weather = this.props.weather;

    if (!weather || weather.state === "unknown") return null;

    return (
      <Flex className={`WeatherSummary ${this.dayOrNight()}`}>
        <Flex.Item>
          <Icon
            className="WeatherIcon"
            icon={weatherStatuses[weather.state].icon}
          />
          <Icon
            className="WeatherIconBg"
            icon={weatherStatuses[weather.state].icon}
          />
        </Flex.Item>
        <Flex.Item className="Content">
          <div className="Temperature">
            {this.getWeatherAttributes().temperature}{" "}
            <Icon className="UnitIcon" icon="mdiTemperatureCelsius" />
          </div>
          <div className="Label">{weatherStatuses[weather.state].label}</div>
        </Flex.Item>
      </Flex>
    );
  }
}

const mapStateToProps = ({ HASSEntities }) => {
  return {
    sun: HASSEntities["sun.sun"],
    weather: HASSEntities["weather.home"],
  };
};

export default connect(mapStateToProps)(WeatherSummary);
