// TODO: Lazy load entity components
import "../styles/Entity.scss";
import Brightness from "./Entities/Brightness";
import ElectricSwitch from "./Entities/ElectricSwitch";
import Gas from "./Entities/Gas";
import Humidity from "./Entities/Humidity";
import Light from "./Entities/Light";
import Motion from "./Entities/Motion";
import React from "react";
import Servo from "./Entities/Servo";
import Switch from "./Entities/Switch";
import Temperature from "./Entities/Temperature";

class Entity extends React.Component {
  render() {
    let component;

    switch (this.props.entity_id) {
      case "light.lamp":
        component = <Light {...this.props} />;
        break;

      case "switch.plug_relay":
        component = <Switch {...this.props} sensor={this.props.additional} />;
        break;

      case "switch.gh_uart1":
      case "switch.gh_uart2":
      case "switch.gh_uart3":
      case "switch.gh_uart4":
        component = <ElectricSwitch {...this.props} />;
        break;

      case "sensor.temperature":
      case "sensor.gh_temperature":
        component = <Temperature {...this.props} />;
        break;

      case "sensor.brightness":
      case "sensor.gh_brightness":
        component = <Brightness {...this.props} />;
        break;

      case "binary_sensor.motion":
        component = <Motion {...this.props} />;
        break;

      case "sensor.humidity":
      case "sensor.gh_humidity":
      case "sensor.gh_soil_moisture":
        component = <Humidity {...this.props} />;
        break;

      case "sensor.gas":
        component = <Gas {...this.props} />;
        break;

      case "input_number.servo_control":
        component = <Servo {...this.props} />;
        break;

      default:
        console.warn(`${this.props.entity_id} is not support in <Entity/>`);
        return null;
    }

    return <div className="col-6">{component}</div>;
  }
}

export default Entity;
