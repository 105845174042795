import React from "react";
import { Flex, Modal } from "antd-mobile";
import Icon from "../Icon";
import Hammer from "hammerjs";

class Servo extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.props.state;
    this.position = parseInt(props.state) || 0;

    this.$entity = React.createRef();
    this.$state = React.createRef();
    this.$live = React.createRef();
  }

  componentDidMount() {
    this.$state.current.textContent = `٪${this.position}`;

    var hammer = new Hammer.Manager(this.$entity.current, {
      inputClass: Hammer.TouchInput,
    });
    this.HammerPan = new Hammer.Pan({
      event: "pan",
      enable: false,
      direction: Hammer.DIRECTION_VERTICAL,
    });

    hammer.add(new Hammer.Tap());
    hammer.add(this.HammerPan);
    hammer.add(
      new Hammer.Press({
        time: 500,
      })
    );

    hammer.on("tap", this.onTap);
    hammer.on("press", this.onPress);
    hammer.on("pan", this.onPan);
    hammer.on("panend pancancel pressup", this.onPanEnd);
  }

  componentDidUpdate() {
    const position = parseInt(this.props.state) || 0;

    if (position !== this.position) {
      this.position = position;
      this.$state.current.textContent = this.position
        ? `٪${this.position}`
        : "";
    }
  }

  calcPosition(value) {
    value =
      this.cachedPosition + -value / (this.$entity.current.clientHeight / 100);
    return Math.min(Math.max(parseInt(value), 0), 100);
  }

  onTap = () => {
    if (this.position === 0) {
      this.showPositionTip();
      return;
    }

    window.HASS.callService("input_number", "set_value", {
      entity_id: this.props.entity_id,
      value: 0,
    });
  };

  onPress = () => {
    if (this.props.state === "unavailable") return;
    this.HammerPan.set({ enable: true });

    this.cachedPosition = this.position;

    this.$live.current.textContent = `٪${this.position}`;
    this.$entity.current.classList.add("on-pan");
    this.$entity.current.style.backgroundSize = `100% ${this.position}%`;
  };

  onPan = (e) => {
    e.preventDefault();
    const position = this.calcPosition(e.deltaY);
    this.position = position;

    this.$live.current.textContent = `٪${position}`;
    this.$entity.current.style.backgroundSize = `100% ${position}%`;
  };

  onPanEnd = (e) => {
    if (!this.HammerPan.options.enable) return;
    this.HammerPan.set({ enable: false });

    const position = this.calcPosition(e.deltaY);
    this.position = position;
    this.$entity.current.classList.remove("on-pan");
    this.$state.current.textContent = `٪${this.position}`;

    window.HASS.callService("input_number", "set_value", {
      entity_id: this.props.entity_id,
      value: position,
    });
  };

  showPositionTip = () => {
    Modal.alert(
      "راهنما",
      "برای باز کردن رادیاتور، نماد رادیاتور را لمس کرده و کمی نگه دارید، سپس درجه آن را تنظیم کنید.",
      [{ text: "متوجه شدم" }]
    );
  };

  render() {
    let stateClass;
    const state = this.props.state;

    switch (state) {
      case "unavailable":
        stateClass = state;
        break;

      default:
        stateClass = parseInt(state) > 0 ? "on" : "off";
        break;
    }

    return (
      <div
        ref={this.$entity}
        data-state={stateClass}
        data-entity-id={this.props.entity_id}
        className="entity servo no-ripple am-flexbox am-flexbox-dir-column am-flexbox-align-center"
      >
        <Flex.Item className="state">
          <span ref={this.$state}></span>
        </Flex.Item>
        <Flex.Item className="content">
          <Icon
            icon={parseInt(state) > 0 ? "mdiRadiator" : "mdiRadiatorDisabled"}
          />
        </Flex.Item>
        <Flex.Item className="title">
          <span>{this.props.attributes.friendly_name}</span>
        </Flex.Item>
        <Flex.Item className="brightness">
          <span ref={this.$live} className="live"></span>
        </Flex.Item>
      </div>
    );
  }
}

export default Servo;
