import React from "react";
import { Flex } from "antd-mobile";
import Icon from "../Icon";

class Gas extends React.Component {
  render() {
    return (
      <Flex
        direction="column"
        className="entity sensor gas no-ripple"
        data-state={
          !Number.isNaN(parseInt(this.props.state)) ? "on" : "unavailable"
        }
        data-entity-id={this.props.entity_id}
      >
        <Flex.Item className="state">
          <span>
            {!Number.isNaN(parseInt(this.props.state)) ? this.props.state : ""}
            <span className="ppm">ppm</span>
          </span>
        </Flex.Item>
        <Flex.Item className="content">
          <Icon icon="mdiMoleculeCo2" />
        </Flex.Item>
        <Flex.Item className="title">
          <span>{this.props.attributes.friendly_name}</span>
        </Flex.Item>
      </Flex>
    );
  }
}

export default Gas;
