import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { OpenMainMenu } from "../../actions/MainMenu";
import { createActivity } from "../../actions/ManageActivities";
import WelcomeText from "../WelcomeText";
import { WhiteSpace } from "antd-mobile";
import WeatherSummary from "../WeatherSummary";
import RoomsList from "../RoomsList";
import TopNavbar from "../TopNavbar";
import Notifications from "./Notifications";
import Icon from "../Icon";

class Overview extends React.PureComponent {
  constructor(props) {
    super(props);

    this.openNotifications = this.openNotifications.bind(this);
  }

  getNotificationCount() {
    let count = 0;

    _.map(this.props.entities, (entity, key) => {
      if (key.includes("persistent_notification.")) {
        count++;
      }
    });

    return count;
  }

  openNotifications() {
    this.props.createActivity({
      name: "notifications",
      component: <Notifications />,
    });
  }

  render() {
    const sun = this.props.entities["sun.sun"];

    return (
      <div className="activity-layout">
        <div>
          <TopNavbar
            title="داشبورد"
            rightIcon={
              <Icon icon="mdiMenu" onClick={this.props.OpenMainMenu} />
            }
            leftIcons={[
              <Icon
                key="notifications"
                icon={
                  this.getNotificationCount()
                    ? "mdiBellRingOutline"
                    : "mdiBellOutline"
                }
                onClick={() => this.openNotifications()}
              />,
            ]}
          />
        </div>
        <div>
          <WelcomeText
            sun={sun}
            onClick={this.openNotifications}
            notificationCount={this.getNotificationCount()}
          />
          <WhiteSpace size="md" />
          <WeatherSummary />
          <WhiteSpace size="md" />
          <RoomsList />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ HASSEntities }) => {
  return { entities: HASSEntities };
};

export default connect(mapStateToProps, {
  OpenMainMenu,
  createActivity,
})(Overview);
