const type = "CONNECTION";

const Connection = {
  connecting: () => {
    return { type, payload: "CONNECTING" };
  },
  connected: () => {
    return { type, payload: "CONNECTED" };
  },
  reconnecting: () => {
    return { type, payload: "RECONNECTING" };
  },
  disconnected: () => {
    return { type, payload: "DISCONNECTED" };
  },
  error: () => {
    return { type, payload: "ERROR" };
  },
};

export default Connection;
