import React from "react";
import { connect } from "react-redux";
import { createActivity } from "../actions/ManageActivities";
import RoomInfo from "./RoomInfo";
import Icon from "./Icon";
import "../styles/bootstrap-grid.min.css";
import "../styles/RoomsList.scss";
import { Flex } from "antd-mobile";

class RoomsList extends React.Component {
  state = {
    rooms: [
      {
        name: "نشیمن",
        icon: "mdiSofa",
        entities: [
          "light.lamp",
          "binary_sensor.motion",
          "sensor.brightness",
          "sensor.temperature",
          "sensor.humidity",
          "input_number.servo_control",
        ],
      },
      {
        name: "آشپز خانه",
        icon: "mdiMicrowave",
        entities: [
          {
            main: "switch.plug_relay",
            additional: "sensor.plug_wattmeter",
          },
          "sensor.temperature",
          "sensor.gas",
        ],
      },
      {
        name: "گلخانه",
        icon: "mdiFlower",
        entities: [
          "sensor.gh_temperature",
          "sensor.gh_brightness",
          "sensor.gh_humidity",
          "sensor.gh_soil_moisture",
          "switch.gh_uart1",
          "switch.gh_uart2",
          "switch.gh_uart3",
          "switch.gh_uart4",
        ],
      },
      {
        name: "اتاق تماشا",
        icon: "mdiTelevision",
        entities: [],
      },
      {
        name: "اتاق خواب",
        icon: "mdiBed",
        entities: [],
      },
      {
        name: "حمام",
        icon: "mdiShowerHead",
        entities: [],
      },
    ],
  };

  openRoomActivity(room) {
    this.props.createActivity({
      name: "roominfo",
      component: <RoomInfo {...room} />,
    });
  }

  render() {
    return (
      <div className="rooms-list">
        <div className="row">
          {this.state.rooms.map((room, index) => {
            return (
              <div className="col-6" key={index}>
                <Flex
                  direction="column"
                  className="room"
                  onClick={() => this.openRoomActivity(room)}
                >
                  <Flex.Item className="content">
                    <Icon icon={room.icon} />
                  </Flex.Item>
                  <Flex.Item className="title">
                    <span>{room.name}</span>
                  </Flex.Item>
                </Flex>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default connect(null, { createActivity })(RoomsList);
