import React from "react";
import { connect } from "react-redux";
import { createActivity } from "../actions/ManageActivities";
import { Toast, Modal } from "antd-mobile";
import { showToast, hideToast } from "../library/AntHelper";
import HASS from "../home-assistant";
import Main from "./Main";
import Settings from "./Activities/Settings";

class App extends React.Component {
  componentDidMount() {
    window.HASS = new HASS();
  }

  componentDidUpdate() {
    this.updateConnectionStatus();
  }

  /**
   * Update UI related to HA connection status
   */
  updateConnectionStatus() {
    switch (this.props.connection) {
      case "CONNECTING":
        showToast(() => Toast.loading("در حال اتصال", 0));
        break;

      case "CONNECTED":
        setTimeout(hideToast, 500);
        break;

      case "RECONNECTING":
        showToast(() =>
          Toast.loading(
            "در حال اتصال مجدد\n\n" +
            "لطفا از اتصال دستگاهتان به اینترنت اطمینان حاصل نمائید",
            0
          )
        );
        break;

      case "DISCONNECTED":
      case "ERROR":
        hideToast();
        this.modal = Modal.alert(
          "خطا در ارتباط با سرور",
          "لطفا از اتصال دستگاهتان به اینترنت اطمینان حاصل نمائید.",
          [
            {
              text: "تلاش مجدد",
              onPress: () => {
                window.HASS.connect();
                this.modal.close();
              },
            },
            {
              text: "تنظیمات",
              onPress: () =>
                this.props.createActivity({
                  name: "settings",
                  component: <Settings />,
                }),
            },
            {
              text: "خروج از حساب کاربری",
              style: { color: "#f44336" },
              onPress: () =>
                window.HASS.logout()
            },
          ]
        );
        break;

      default:
        break;
    }
  }

  render() {
    return <Main />;
  }
}

const mapStateToProps = ({ connection }) => {
  return { connection };
};

export default connect(mapStateToProps, { createActivity })(App);
