export const OpenMainMenu = () => {
  return {
    type: "OPEN_MAIN_MENU",
  };
};

export const CloseMainMenu = () => {
  return {
    type: "CLOSE_MAIN_MENU",
  };
};
