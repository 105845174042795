import React from "react";
import { connect } from "react-redux";
import { createActivity, closeActivity } from "../actions/ManageActivities";
import Overview from "./Activities/Overview";
import { Toast } from "antd-mobile";
import { showToast } from "../library/AntHelper";
import moment from "moment";
import "../styles/ActivityManager.scss";

class ActivityManager extends React.Component {
  static defaultProps = {
    activities: [],
  };

  componentDidMount() {
    window.addEventListener("popstate", this.onBackPressed, false);

    this.props.createActivity({
      name: "overview",
      component: <Overview />,
      classNames: "no-animation",
    });
  }

  componentDidUpdate() {
    if (this.props.connection === "CONNECTED") {
      this.pushState();
    }
  }

  /**
   * Redirect to new url hash
   * @param {string} url
   */
  pushState(url = null) {
    window.history.pushState(
      null,
      document.title,
      `${window.location.origin}/${url !== null ? url : moment.now()}`
    );
  }

  onBackPressed = () => {
    console.log("popstate");

    var activity = this.props.activities[this.props.activities.length - 1];

    // If there is more than one activity
    if (this.props.activities.length > 1) {
      // Add new state to prevent empty stack
      this.pushState();

      var shouldCloseActivity = true;

      // Run activity onBackPressed method
      if (activity.onBackPressed) {
        shouldCloseActivity = activity.onBackPressed();
      }

      if (shouldCloseActivity) {
        this.props.closeActivity();
      }
    } else {
      this.pushState("");
      showToast(
        () =>
          Toast.info("برای خروج از برنامه\nدو بار دکمه‌ی بازگشت را بزنید", 0),
        1.5
      );
      setTimeout(
        () =>
          window.history.pushState(
            { type: "root" },
            document.title,
            `/${moment.now()}`
          ),
        1500
      );
    }
  };

  render() {
    return this.props.activities.map((activity) => {
      return (
        <div
          className={`activity ${activity.classNames}`.trim()}
          key={activity.key}
        >
          {activity.component}
        </div>
      );
    });
  }
}

const mapStateToProps = ({ activities, connection }) => {
  return { activities, connection };
};

export default connect(mapStateToProps, {
  createActivity,
  closeActivity,
})(ActivityManager);
