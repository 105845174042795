import React from "react";
import { connect } from "react-redux";
import { closeActivity } from "../actions/ManageActivities";
import { Flex } from "antd-mobile";
import Icon from "./Icon";
import "../styles/TopNavbar.scss";

class TopNavbar extends React.Component {
  static defaultProps = {
    title: "",
    rightIcon: null,
    leftIcons: [],
  };

  render() {
    return (
      <div className="top-navbar">
        <Flex className="top-navbar-flex" justify="between">
          <Flex.Item className="right-menu">
            {this.props.rightIcon || (
              <Icon icon="mdiArrowRight" onClick={this.props.closeActivity} />
            )}
          </Flex.Item>
          <Flex.Item className="title">
            <span>{this.props.title}</span>
          </Flex.Item>
          <Flex.Item className="left-menu">{this.props.leftIcons}</Flex.Item>
        </Flex>
      </div>
    );
  }
}

export default connect(null, { closeActivity })(TopNavbar);
