const manageActivities = (activities = [], action) => {
  switch (action.type) {
    case "CREATE_ACTIVITY":
      return [
        ...activities,
        {
          classNames: "",
          key: new Date().getTime(),
          ...action.payload,
        },
      ];
    case "CLOSE_ACTIVITY":
      activities[activities.length - 1].classNames += " closing";
      return [...activities];
    case "DELETE_ACTIVITY":
      return [...activities.slice(0, activities.length - 1)];
    default:
      return activities;
  }
};

export default manageActivities;
