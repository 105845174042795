import {
  getAuth,
  createConnection,
  subscribeEntities,
  callService,
  ERR_INVALID_AUTH,
} from "home-assistant-js-websocket";
import moment from "moment";
import store from "./store";
import { Entities as EntitiesAC } from "./actions/HomeAssistant";
import Connection from "./actions/Connection";
import { storageAvailable } from "./library/Helper";

/**
 * HomeAssistant helper class
 */
class HASS {
  auth;
  connection;

  defaults = {
    authenticationPath: "authentication",
    serverAddress: "http://localhost:8123",
  };

  constructor() {
    this.defaults.serverAddress =
      process.env.REACT_APP_SERVER_ADDRESS || "http://localhost:8123";

    this.connect();
  }

  /**
   * Save HA credential
   * @param {object} credential HA credential object
   */
  saveCredential(credential) {
    storageAvailable("localStorage")
      ? localStorage.setItem("HACredential", JSON.stringify(credential))
      : console.warn(
          "saveCredential: Device does not support Local Storage API"
        );
  }

  /**
   * Load HA saved credential
   */
  loadCredential() {
    return new Promise((resolve) =>
      resolve(
        storageAvailable("localStorage")
          ? JSON.parse(localStorage.getItem("HACredential"))
          : null
      )
    );
  }

  /**
   * Clear HA saved credential
   */
  clearCredentials() {
    storageAvailable("localStorage")
      ? localStorage.removeItem("HACredential")
      : console.warn(
          "clearCredentials: Device does not support Local Storage API"
        );
  }

  /**
   * Logout and reload page
   */
  async logout() {
    try {
      await this.auth.revoke();
    } catch (error) {
      console.error("Error on revoke auth, Error: ", error);
    }

    this.clearCredentials();
    window.location.href = window.location.origin;
  }

  /**
   * Authenticate with HA
   */
  async authenticate() {
    try {
      this.auth = await getAuth({
        hassUrl: this.getServerAddress(),
        redirectUrl: `${window.location.protocol}//${window.location.host}/${this.defaults.authenticationPath}`,
        saveTokens: this.saveCredential,
        loadTokens: this.loadCredential,
      });
    } catch (error) {
      console.error(["HASS Auth error", error]);

      if (error === ERR_INVALID_AUTH) {
        this.logout();
      }

      return false;
    }

    if (window.location.pathname === `/${this.defaults.authenticationPath}`) {
      window.history.pushState(
        null,
        document.title,
        `${window.location.origin}/${moment.now()}`
      );
    }

    return true;
  }

  /**
   * Connect to HA websocket API
   */
  async connect() {
    store.dispatch(Connection.connecting());

    // Authenticate if is not processed yet, reject connect process on authenticate error
    if (!this.auth && !(await this.authenticate())) {
      console.error("Connect process rejected on authentication error");
      setTimeout(() => store.dispatch(Connection.error()), 300);
      return;
    }

    try {
      this.connection = await createConnection({
        auth: this.auth,
        setupRetry: 3,
      });
      this.connected();
    } catch (error) {
      console.error(["HASS Connection error", error]);

      if (error === ERR_INVALID_AUTH) {
        this.logout();
        return;
      }

      setTimeout(() => store.dispatch(Connection.error()), 300);
    }
  }

  /**
   * Close current HA websocket connection
   */
  disconnect = () => {
    if (this.connection) {
      this.connection.close();
    }
    store.dispatch(Connection.disconnected());
  };

  /**
   * Disconnect and reconnect HA websocket connection
   */
  reconnect = () => {
    this.disconnect();
    this.connect();
  };

  /**
   * Run tasks after connect to HA websocket API
   */
  connected() {
    store.dispatch(Connection.connected());

    this.connection.addEventListener("ready", () =>
      store.dispatch(Connection.connected())
    );

    this.connection.addEventListener("disconnected", () =>
      store.dispatch(Connection.reconnecting())
    );

    this.connection.addEventListener("closed", () =>
      store.dispatch(Connection.disconnected())
    );

    this.connection.addEventListener("reconnect-error", (connection, error) => {
      console.error(["HASS reconnect-error:", error]);
      store.dispatch(Connection.disconnected());
    });

    subscribeEntities(this.connection, (entities) => {
      store.dispatch(EntitiesAC(entities));
    });
  }

  /**
   * Return HA server address
   */
  getServerAddress() {
    return storageAvailable("localStorage")
      ? localStorage.getItem("HAServerAddress") || this.defaults.serverAddress
      : console.warn(
          "getServerAddress: Device does not support Local Storage API"
        ) || this.defaults.serverAddress;
  }

  /**
   * Set HA server address
   * @param {string} url HA server address
   */
  setServerAddress(url) {
    if (storageAvailable("localStorage")) {
      localStorage.setItem("HAServerAddress", url);
      this.setCredentialServerAddress(url);
    } else {
      console.warn(
        "setServerAddress: Device does not support Local Storage API"
      );
    }
  }

  /**
   * Set HA Aut server address
   * @param {string} url HA server address
   */
  async setCredentialServerAddress(url) {
    if (storageAvailable("localStorage")) {
      var credentials = this.auth.data;
      credentials.hassUrl = url;
      this.auth = undefined;
      this.saveCredential(credentials);
    } else {
      console.warn(
        "setServerAddress: Device does not support Local Storage API"
      );
    }
  }

  /**
   * Call a service
   * @param {string} domain Service domain
   * @param {string} service Service name
   * @param {object} data Additional data for service call
   */
  callService(domain, service, data) {
    callService(this.connection, domain, service, data);
    // TODO: accept callback or use promise
  }
}

export default HASS;
