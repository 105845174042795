import { combineReducers } from "redux";
import ManageActivities from "./ManageActivities";
import MainMenu from "./MainMenu";
import { HASSEntities } from "./HomeAssistant";
import Connection from "./Connection";

export default combineReducers({
  activities: ManageActivities,
  mainMenu: MainMenu,
  HASSEntities: HASSEntities,
  connection: Connection,
});
