import React from "react";
import { connect } from "react-redux";
import { closeActivity } from "../../actions/ManageActivities";
import TopNavbar from "../TopNavbar";
import { WhiteSpace, InputItem, Button } from "antd-mobile";
import Icon from "../Icon";

class Overview extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      serverAddress: window.HASS.getServerAddress(),
    };
  }

  /**
   * Back button handler
   */
  onClickBack() {
    if (["CONNECTED", "CONNECTING"].indexOf(this.props.connection) === -1) {
      window.HASS.reconnect();
    }

    this.props.closeActivity();
  }

  /**
   * Save button handler
   */
  onClickSave() {
    window.HASS.setServerAddress(this.state.serverAddress);
    window.HASS.reconnect();
    this.props.closeActivity();
  }

  /**
   * ServerAddress input field handler
   * @param {string} value ServerAddress input field value
   */
  onChangeServerAddress = (value) => {
    this.setState({ serverAddress: value });
  };

  render() {
    return (
      <div className="activity-layout">
        <div>
          <TopNavbar
            title="تنظیمات"
            rightIcon={
              <Icon icon="mdiArrowRight" onClick={() => this.onClickBack()} />
            }
          />
        </div>
        <div>
          <InputItem
            type="text"
            value={this.state.serverAddress}
            onChange={this.onChangeServerAddress}
            className="am-input-text-left"
            placeholder="http://smarthome:8123"
            ref={(el) => (this.serverAddressRef = el)}
          >
            <div onClick={() => this.serverAddressRef.focus()}>آدرس سرور:</div>
          </InputItem>
          <WhiteSpace size="lg" />
          <Button type="primary" onClick={(e) => this.onClickSave()}>
            ذخیره
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ connection }) => {
  return { connection };
};

export default connect(mapStateToProps, { closeActivity })(Overview);
